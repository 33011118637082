import { useRef } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useClickOutside from '@utils/useClickOutside';

import { useStyles } from './useStyles';

interface InputErrorProps {
  error: string | null | undefined;
  className?: string;
  onClickErrorCloseIcon?: () => void;
}
const InputError = ({
  error,
  className,
  onClickErrorCloseIcon,
}: InputErrorProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  useClickOutside(ref, () => {
    onClickErrorCloseIcon && onClickErrorCloseIcon();
  });

  if (!error) return null;

  return (
    <div className={className} ref={ref}>
      <div className={classes.bubble}>
        <Typography variant="caption" color="cinnamon.main">
          {error}
        </Typography>
        <CloseIcon
          fontSize="medium"
          className={classes.closeIcon}
          onClick={onClickErrorCloseIcon}
        />
      </div>
    </div>
  );
};

export default InputError;
