import React from 'react';
import Button, { ButtonClasses } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export enum ButtonColorVariant {
  GARAMMASALA = 'garamMasala',
  CINNAMON = 'cinnamon',
  WHITEPEPPER = 'whitePepper',
  CUMIN = 'cumin',
  TURMERIC = 'turmeric',
  INHERIT = 'inherit',
  ERROR = 'error',
  SUCCESS = 'success',
  SECONDARY = 'secondary',
  PRIMARY = 'primary',
}

interface ButtonProps {
  children?: React.ReactNode;
  classes?: Partial<ButtonClasses> | undefined;
  onClick?: any;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'turmeric'
    | 'cinnamon'
    | 'garamMasala'
    | 'cumin'
    | 'whitePepper'
    | 'lightGray'
    | 'offWhite'
    | 'dark3';
  variant?: 'contained' | 'outlined' | 'text';
  textColor?: any;
  borderRadius?: string;
  width?: string;
  height?: string;
  hoveredColor?: string;
  className?: string;
  disabled?: boolean;
  disabledColor?: string;
  fontVariant?: 'button1' | 'button2' | 'caption' | 'button3' | 'buttonSmall';
  border?: string;
}

const MyButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: 'Spoof-Bold',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
  textTransform: 'none',
}));

export default function CuminButton({
  children,
  textColor = 'black',
  borderRadius,
  width,
  height = '48',
  onClick = () => {},
  hoveredColor,
  className,
  disabled = false,
  disabledColor,
  color,
  fontVariant = 'button2',
  border = 'none',
  ...props
}: ButtonProps) {
  return (
    <MyButton
      {...props}
      disabled={disabled}
      disableElevation
      disableRipple
      disableFocusRipple
      onClick={onClick}
      color={color}
      sx={{
        border: border,
        borderRadius: `${borderRadius}px`,
        width: `${width}`,
        height: `${height}px`,
        '&:disabled': {
          backgroundColor: disabledColor || `#E8AD81`,
          cursor: 'not-allowed',
        },
        '&:hover': {
          backgroundColor:
            hoveredColor || color ? `${color}.dark` : 'transparent',
          border: border ? border : 'none',
        },
        minWidth: width || '200px',
        minHeight: '48px',
      }}
      className={className}
    >
      <Typography variant={fontVariant} color={textColor} align="center">
        {children}
      </Typography>
    </MyButton>
  );
}
