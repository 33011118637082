import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  bubble: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    columnGap: '15px',
    padding: theme.spacing(1.5),
    position: 'relative',
    background: '#fff2e8',
    WebkitBorderRadius: '8px',
    MozBorderRadius: '8px',
    borderRadius: '8px',
    border: '#d46a19 solid 1px',
    '&::after': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 25px 30px',
      borderColor: '#fff2e8 transparent',
      display: 'block',
      width: '0',
      zIndex: 1,
      top: '-30px',
      left: '21px',
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '0 25px 30px',
      borderColor: '#d46a19 transparent',
      display: 'block',
      width: '0',
      zIndex: 0,
      top: '-31px',
      left: '21px',
    },
  },

  closeIcon: {
    cursor: 'pointer',
    color: theme.palette.cinnamon.main,
  },
});
